<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Liste des Catégorie de Expertises"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.ajout
                type="submit"
                variant="success"
                class="mr-1"
                size="sm"
              >
                Créer un type d'abonnement
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="abonnements"
          :fields="fields"
          :sort-by.sync="sortBy"
          :empty-filtered-text="'Aucun résultat n\'a été trouvé'"
          :empty-text="'Aucun résultat n\'a été trouvé'"
          show-empty
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modifier
                @click="getAbonnement(data.item)"
              >
                <feather-icon icon="EditIcon" />
                Modifier
                <span class="align-middle text-primary ml-50" />
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                Supprimer
                <span class="align-middle text-danger ml-50" />
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="abonnements.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-modal
      id="ajout"
      ref="my-modal"
      button-size="sm"
      ok-title="Créer"
      cancel-title="Fermer"
      cancel-variant="gradient-danger"
      ok-variant="gradient-success"
      title="Créer un type d'expertise"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <validation-observer ref="ValidationRules">
        <b-form @submit.prevent="handleSubmitModal">

          <b-form-group
            label="Libellé"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="libelle"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="form.libelle"
                :state="errors.length > 0 ? false : null"
                placeholder="Libellé"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Prix"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="prix"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="form.prix"
                :state="errors.length > 0 ? false : null"
                placeholder="Prix"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Nombre"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="form.nombre"
                :state="errors.length > 0 ? false : null"
                placeholder="Nombre"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="form.description"
              placeholder="Description"
              rows="3"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modifier"
      ref="my-modal1"
      button-size="sm"
      ok-title="Modifier"
      cancel-title="Fermer"
      cancel-variant="gradient-danger"
      ok-variant="gradient-success"
      title="Modification"
      no-close-on-backdrop
      @ok="handleUpdateOk"
    >
      <validation-observer ref="ValidationRules">
        <b-form @submit.prevent="handleUpdateModal">

          <b-form-group
            label="Libellé"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="libelle"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="form.libelle"
                :state="errors.length > 0 ? false : null"
                placeholder="Libellé"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Prix"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="prix"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="form.prix"
                :state="errors.length > 0 ? false : null"
                placeholder="Prix"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Nombre"
            label-for="label"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="form.nombre"
                :state="errors.length > 0 ? false : null"
                placeholder="Nombre"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="form.description"
              placeholder="Description"
              rows="3"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BForm,
  BFormTextarea, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useAbonnements from '@/services/admin/abonnementService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      abonnements, getAbonnements, createAbonnement, errors, abonnementSuccess, abonnement, updateAbonnement, loader,
    } = useAbonnements()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'prix', label: 'Prix', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      libelle: '',
      description: '',
      prix: '',
      nombre: '',
    })
    /* eslint-disable global-require */
    const items = abonnements

    onMounted(() => {
      getAbonnements()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    // Reset Form
    const resetForm = () => {
      form.libelle = null
      form.description = null
      form.slug = null
    }

    // Enregistrer une abonnement
    const storeAbonnement = async () => {
      // showToast('warning', 'StarIcon')
      await createAbonnement({ ...form }).then(() => {
        if (abonnementSuccess.value === true) {
          resetForm()
        }
      })
    }

    // Obtenir une abonnement par son slug
    const getAbonnement = async item => {
      form.id = item.id
      form.libelle = item.libelle
      form.description = item.description
      form.prix = item.prix
      form.nombre = item.nombre
    }

    // Modifier une abonnement
    const editAbonnement = async () => {
      await updateAbonnement(form).then(() => {
        if (abonnementSuccess.value === true) {
          resetForm()
        }
      })
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      onFiltered,
      abonnements,
      form,
      errors,
      storeAbonnement,
      abonnementSuccess,
      required,
      formatter,
      abonnement,
      getAbonnement,
      editAbonnement,
      loader,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.storeAbonnement()
          if (this.abonnementSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
    handleUpdateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleUpdateModal()
    },
    handleUpdateModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.editAbonnement()
          if (this.abonnementSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal1'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
